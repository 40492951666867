import TWEEN from '@tweenjs/tween.js';
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import Widget from './widget';

const CONTROL = false;

function lerp(vectorList, t) {
  const len = vectorList.length;
  const id0 = Math.max(0, Math.min(len - 1, Math.floor(t * len)));
  const id1 = Math.min(id0 + 1, len - 1);
  const res = new THREE.Vector3();
  // console.log('lerp', t.toFixed(2), id0, id1, (len * t - id0).toFixed(2));
  return res.lerpVectors(
    vectorList[id0],
    vectorList[id1],
    len * t - id0
  )
}

const YAXIS = new THREE.Vector3(0, 1, 0);
const ZAXIS = new THREE.Vector3(0, 0, 1);

export default class Camera extends Widget {
  constructor(game) {
    super(game);
    const ar = game.canvas.clientWidth / game.canvas.clientHeight;
    this.camera = new THREE.PerspectiveCamera(50, ar, 0.1, 2000);
    this.camera.up.set(0, 0, 1);

    // eslint-disable-next-line
    if (CONTROL) {
      // 
      const controls = new OrbitControls(this.camera, game.canvas);
      controls.enablePan = false;
      controls.minDistance = 3;
      controls.maxDistance = 100;
      controls.maxPolarAngle = Math.PI * 0.55;
      controls.minPolarAngle = Math.PI * 0.25;
      controls.target.set(this.game.distance, 0, 20);
      // controls.target.set(750, 0, 180);
      controls.update();
    }

    // Position & look targets
    this.posTarget = new THREE.Vector3();
    this.lookTarget = new THREE.Vector3();
    this.look = new THREE.Vector3();

    // Camera look pointers
    this.playerCamPos = new THREE.Vector3(-8, 0, 4);
    this.playerLookPos = new THREE.Vector3(1, 0, 3);
    this.playerCamPsi = 0;
    this.playerCamTheta = 0;

    // Setup look
    const initDist = 30;
    this.posTarget.set(game.distance + 2 * initDist, 0, initDist);
    this.camera.position.copy(this.posTarget);
    this.lookTarget.set(-2, 0, -1).add(this.posTarget);
    this.look.copy(this.lookTarget);
    this.camera.lookAt(this.look);

    // 
    this.trackCharacter = null;
    this.tPrev = null;
    this.posPrev = null;
    this.speedFilt = 0;
  }

  getCamera() {
    return this.camera;
  }

  resize(canvas) {
    this.camera.aspect = canvas.clientWidth / canvas.clientHeight;
    this.camera.updateProjectionMatrix();
  }

  async animateIntro(duration) {
    const showCurve = false;
    const posCurve = new THREE.CatmullRomCurve3([
      this.camera.position.clone(),
      new THREE.Vector3(this.game.distance, 0, 4),
      new THREE.Vector3(this.game.distance / 2, 0, 4),
      new THREE.Vector3(10, -this.game.width / 2, 5),
    ]);
    // const camCurve = [
    //   this.initLook,
    //   new THREE.Vector3(-2, 0, -1),
    // ]

    // Print curve if needed
    if (showCurve) return showCurve(this.game.scene, posCurve)

    const lookDelta = new THREE.Vector3(-2, 0, -1);
    new TWEEN.Tween({ t: 0 }, this.tweens)
      .to({ t: 1 })
      .easing(TWEEN.Easing.Quadratic.InOut)
      .duration(duration)
      .onUpdate(({ t }) => {
        this.posTarget.copy(posCurve.getPointAt(t));
        this.lookTarget.copy(lookDelta).add(this.posTarget);
      })
      .start();
    return this.tweens.promise();
  }

  async animateSpawn(duration) {
    const posCurve = new THREE.CatmullRomCurve3([
      this.camera.position.clone(),
      new THREE.Vector3(10, this.game.width / 2, 5),
    ]);
    const lookDelta = new THREE.Vector3(-2, 0, -1);
    new TWEEN.Tween({ t: 0 }, this.tweens)
      .to({ t: 1 })
      .easing(TWEEN.Easing.Quadratic.InOut)
      .duration(duration)
      .onUpdate(({ t }) => {
        this.posTarget.copy(posCurve.getPointAt(t));
        this.lookTarget.copy(lookDelta).add(this.posTarget);
      })
      .start();
    return this.tweens.promise();
  }

  async animateFpv(duration) {
    const lookDelta = new THREE.Vector3(-2, 0, -1);
    const posCurve = new THREE.CatmullRomCurve3([
      this.camera.position.clone().add(lookDelta),
      new THREE.Vector3(0, this.game.width, 8),
      new THREE.Vector3(-10, 0, 5),
    ]);
    const camCurve = [
      this.camera.position.clone().add(lookDelta),
      new THREE.Vector3(0, 0, 0),
    ]
    new TWEEN.Tween({ t: 0 }, this.tweens)
      .to({ t: 1 })
      .easing(TWEEN.Easing.Quadratic.InOut)
      .duration(duration)
      .onUpdate(({ t }) => {
        this.posTarget.copy(posCurve.getPointAt(t));
        this.lookTarget.copy(lerp(camCurve, t));
      })
      .start();
    return this.tweens.promise();
  }

  async animateLookRot() {
    new TWEEN.Tween({ theta: this.playerCamTheta, psi: this.playerCamPsi }, this.tweens)
      .to({ theta: 0.2, psi: Math.PI }, 1000)
      .easing(TWEEN.Easing.Quadratic.InOut)
      .onUpdate(({ theta, psi }) => {
        this.playerCamTheta = theta;
        this.playerCamPsi = psi;
      })
      .start();
    return this.tweens.promise();
  }

  onDrag(dx, dy) {
    this.playerCamTheta += dy / 200;
    this.playerCamPsi -= dx / 200;
    if (this.playerCamPsi > Math.PI) this.playerCamPsi -= 2 * Math.PI;
    if (this.playerCamPsi < -Math.PI) this.playerCamPsi += 2 * Math.PI;
    this.playerCamTheta = Math.min(Math.max(this.playerCamTheta, -0.4), 0.8);
  }

  update() {
    super.update();
    // Filter out camera position & look
    const t = Date.now();

    // Track character if needed
    let alpha = 0.1;
    if (this.trackCharacter) {
      // Filter camera speed
      // Now 
      const modelPos = this.trackCharacter.model.position;
      if (!this.tPrev) this.tPrev = t;
      if (!this.posPrev) this.posPrev = modelPos.clone();
      // this.speedFilt += (this.posPrev.sub(modelPos).length() - this.speedFilt) * 0.05;
      if (!this._camPos) this._camPos = new THREE.Vector3();
      if (!this._lookPos) this._lookPos = new THREE.Vector3();
      this._camPos.copy(this.playerCamPos);
      // this._camPos.x *= (1 + 0.2 * this.speedFilt);
      this._lookPos.copy(this.playerLookPos);
      // Rotate cam & look
      this._camPos.applyAxisAngle(YAXIS, this.playerCamTheta);
      this._lookPos.applyAxisAngle(YAXIS, this.playerCamTheta);
      this._camPos.applyAxisAngle(ZAXIS, this.playerCamPsi);
      this._lookPos.applyAxisAngle(ZAXIS, this.playerCamPsi);
      // Add in player position
      this.posTarget.copy(modelPos).add(this._camPos)
      this.lookTarget.copy(modelPos).add(this._lookPos)
      // 
      this.posPrev = modelPos.clone();
      alpha = 0.5;
    }

    // Update camera,
    if (CONTROL) return;
    this.camera.position.add(this.posTarget.clone().sub(this.camera.position).multiplyScalar(alpha));
    this.look.add(this.lookTarget.clone().sub(this.look).multiplyScalar(alpha));
    this.camera.lookAt(this.look);
  }
}


// function showCurve(scene, curve) {
//   const points = curve.getPoints(50);
//   const geometry = new THREE.BufferGeometry().setFromPoints(points);
//   const material = new THREE.LineBasicMaterial({ color: 0xff0000 });
//   const curveObject = new THREE.Line(geometry, material);
//   this.game.scene.add(curveObject);
// }