<template lang='pug'>
v-app
  v-main
    router-view
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>

<style>
@font-face {
  font-family: "nasalization";
  src: local("nasalization"),
    url(assets/fonts/nasalization.otf) format("truetype");
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-col {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nasa-text {
  font-family: "nasalization";
  font-weight: 300;
}
</style>
