<template lang='pug'>
Game
</template>

<script>
import Game from "../components/Game";

export default {
  name: "Home",

  components: {
    Game,
  },
};
</script>
