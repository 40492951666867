<template lang="pug">
.flex-col
  .flex-row
    v-text-field(v-model="name", label="name", outlined, dense, hide-details)
    v-btn(icon, @click="setName")
      v-icon mdi-check
</template>

<script>
export default {
  data: () => ({
    name: null,
  }),

  methods: {
    setName() {
      //
    },
  },
};
</script>
