import { loadPetModel } from '../utils/utils'
import TWEEN from '@tweenjs/tween.js';
import WireframeAnim from '../components/wireframeAnim';
import * as THREE from 'three';
import Ring from '../components/ring';
import Widget from './widget';
import { PhaseType } from '../core/api';
import { Howl } from 'howler';

const phaseSound = new Howl({
  src: [require('@/assets/sounds/phase.mp3')],
});
const jumpSound = new Howl({
  src: [require('@/assets/sounds/jump.wav')],
});
const wonSound = new Howl({
  src: [require('@/assets/sounds/won.wav')],
});

const JUMP_HEIGHT = 1;
const BACKFLIP_HEIGHT = 2;

export default class Character extends Widget {
  constructor(game, playerId, petId, y) {
    super(game);
    this.playerId = playerId;
    this.petId = petId;

    this.y = y;
    this.load();
    // Cache position and target position
    // this.pos = 0;
    this.targetPos = 0;
    this.ring = null;
    this.arrived = false;
  }

  async load() {
    super.load();

    const url = `/data/${this.petId}/model.vox`;
    this.model = await loadPetModel(url);
    // this.model.children[0].castShadow = true;
    // this.model.children[0].receiveShadow = true;
    this.model.position.y = this.y;
    this.game.scene.add(this.model);
    // Load anim
    this.introAnim = new WireframeAnim(this.game.scene, this.model);
  }

  async animateIntro(duration) {
    if (!this.introAnim)
      return console.error('Model not loaded')
    phaseSound.play();
    await this.introAnim.animate(duration);
  }

  onMessage(msg) {
    super.onMessage(msg);
    this.player = msg.players[this.playerId];
    // Animate player
    if (!this.player.pruned)
      this._animate(this.player.phasePos + this.player.jumpDistance, false)
    // Arrived
    if (this.player.arrived > 0 && this.player.arrived <= 3) {
      this._animateArrived(this.player.arrived);
    }
  }

  async onNewPhase(phase) {
    if (phase == PhaseType.DONE) {
      if (this.player.arrived < 1 || this.player.arrived > 3) {
        setTimeout(() => this.animateFall(), 2000);
      }
    }
  }

  async animateFall() {
    this.tweens.removeAll();
    new TWEEN.Tween(this.model.position, this.tweens)
      .easing(TWEEN.Easing.Quadratic.In)
      .to({ z: -30 }, 3000)
      .start();
    new TWEEN.Tween({ t: 1 }, this.tweens)
      .easing(TWEEN.Easing.Quadratic.In)
      .to({ t: 0 }, 3000)
      .onUpdate(({ t }) => this.model.scale.setScalar(t))
      .start();
    await this.tweens.promise();
  }

  prune() {
    this._animate(this.player.phasePos, true);
  }

  _animate(pos, prune) {
    if (this.targetPos == pos) return;
    const player = this.player;
    this.targetPos = pos;
    if (prune)
      this._animateBackflips(this.model.position.x, pos, 2000);
    else {
      const duration = this.msg.jumpDuration;
      this._animateJump(this.model.position.x, pos, duration);
    } 
  }

  async _animateArrived(arrivedOrder) {
    // Only for the top 3
    if (this.arrived) return;
    if (arrivedOrder < 1 || arrivedOrder > 3) return;
    this.arrived = true;
    // Camera
    if (this.playerId == this.game.playerId)
      this.game.camera.animateLookRot();
    // 
    this.tweens.removeAll();
    this.model.rotation.y = 0;
    // Hover
    new TWEEN.Tween(this.model.position, this.tweens)
      .easing(TWEEN.Easing.Quadratic.InOut)
      .to({ z: 1 }, 1000)
      .start();
    // Setup targets
    const axis = new THREE.Vector3(1, 0, 0);
    let target = new THREE.Vector3(this.game.distance, 0, this.game.width);
    let color;
    if (arrivedOrder == 1) {
      color = new THREE.Color(0xdbbd0f);
    } else if (arrivedOrder == 2) {
      target.applyAxisAngle(axis, -Math.PI / 4);
      color = new THREE.Color(0x9e9e9e);
    } else if (arrivedOrder == 3) {
      target.applyAxisAngle(axis, Math.PI / 4);
      color = new THREE.Color(0xb8600f);
    }
    // Create ring
    wonSound.play();
    this.ring = new Ring(this.model, .5, .05, new THREE.Color(0xffffff), false)
    await this.ring.animateShow();
    this.ring.animateTiles(color, new THREE.Color(0xffffff));
    const intermediate = new THREE.Vector3(
      this.model.position.x,
      (this.model.position.y + target.y) / 2,
      target.z
    );
    const posCurve = new THREE.CatmullRomCurve3([
      this.model.position.clone(),
      intermediate,
      target,
    ]);
    new TWEEN.Tween({ t: 0 }, this.tweens)
      .to({ t: 1 }, 2000)
      .easing(TWEEN.Easing.Quadratic.InOut)
      .onUpdate(({ t }) => {
        this.model.position.copy(posCurve.getPointAt(t));
      })
      .start();
    await this.tweens.promise();
    // Rotate
    new TWEEN.Tween(this.model.rotation, this.tweens)
      .easing(TWEEN.Easing.Quadratic.InOut)
      .to({ z: Math.PI }, 1000)
      .start();
    await this.tweens.promise();
  }

  async _animateJump(xStart, xEnd, duration) {
    if (this.playerId == this.game.playerId)
      jumpSound.play();
    // Reset player position
    this.tweens.removeAll();
    this.model.position.x = xStart;
    // Reset rotations
    this.model.rotation.y = 0;
    this.model.children[0].rotation.z = 0;
    // 
    const dx = xEnd - xStart;
    // 
    const dz = JUMP_HEIGHT;
    const angle = (Math.PI / 2 - Math.atan2(4 * dz, dx)) / 2;
    const squeezeDuration = duration * 0.2;
    const jumpDuration = duration - squeezeDuration;
    const squeeze = (up) => {
      const factor = 0.9;
      return new TWEEN.Tween({ x: 0 }, this.tweens)
        .easing(up ? TWEEN.Easing.Quadratic.In : TWEEN.Easing.Quadratic.Out)
        .to({ x: 1 }, squeezeDuration / 2)
        .onUpdate(({ x }) => {
          this.model.scale.z = factor + (1 - factor) * 2 * Math.abs(x - 0.5);
          this.model.rotation.y = up ? x * angle : (x - 1) * angle;
        })
        .onComplete(() => this.model.scale.z = 1);
    }
    const jump = (up) => {
      const linearTween = new TWEEN.Tween({
        x: up ? xStart : xStart + dx / 2,
        r: up ? angle : 0,
      }, this.tweens)
        .easing(TWEEN.Easing.Linear.None)
        .to({
          x: up ? xStart + dx / 2 : xStart + dx,
          r: up ? 0 : -angle
        }, jumpDuration / 2)
        .onUpdate(({ x, r }) => {
          this.model.position.x = x;
          this.model.rotation.y = r;
        });
      return new TWEEN.Tween({ z: up ? 0 : dz }, this.tweens)
        .easing(up ? TWEEN.Easing.Quadratic.Out : TWEEN.Easing.Quadratic.In)
        .onStart(() => linearTween.start())
        .to({ z: up ? dz : 0 }, jumpDuration / 2)
        .onUpdate(({ x, z, r }) => {
          this.model.position.z = z;
        });
    }
    const squeezeDown = squeeze(false);
    const jumpDown = jump(false).chain(squeezeDown);
    const jumpUp = jump(true).chain(jumpDown);
    const squeezeUp = squeeze(true).chain(jumpUp);
    squeezeUp.start();
  }

  async _animateBackflips(xStart, xEnd, duration) {
    // Reset player position
    this.tweens.removeAll();
    this.model.position.x = xStart;
    // Reset rotations
    this.model.rotation.y = 0;
    this.model.children[0].rotation.z = 0;
    // 
    const dx = xEnd - xStart;
    const dz = BACKFLIP_HEIGHT;
    const squeezeDuration = duration * 0.2;
    const jumpDuration = duration - squeezeDuration;
    const rotCount = 1; // Math.ceil(Math.abs(dx / 10)); // Update?
    const jump = (up) => {
      const linearTween = new TWEEN.Tween({
        x: up ? xStart : xStart + dx / 2,
        r: up ? 0 : rotCount * Math.PI,
      }, this.tweens)
        .easing(TWEEN.Easing.Linear.None)
        .to({
          x: up ? xStart + dx / 2 : xStart + dx,
          r: up ? rotCount * Math.PI : rotCount * 2 * Math.PI
        }, jumpDuration / 2)
        .onUpdate(({ x, r }) => {
          this.model.position.x = x;
          this.model.children[0].rotation.z = r;
        });
      return new TWEEN.Tween({ z: up ? 0 : dz }, this.tweens)
        .easing(up ? TWEEN.Easing.Quadratic.Out : TWEEN.Easing.Quadratic.In)
        .onStart(() => linearTween.start())
        .to({ z: up ? dz : 0 }, jumpDuration / 2)
        .onUpdate(({ x, z, r }) => {
          this.model.position.z = z;
        });
    }
    const jumpDown = jump(false);
    const jumpUp = jump(true).chain(jumpDown);
    jumpUp.start();
  }

  // async animateHop(direction = null) {
  //   this.tweens.removeAll();
  //   const rz = this.model.rotation.z;
  //   if (direction == null) direction = rz;
  //   const delta = Math.atan2(Math.sin(direction - rz), Math.cos(direction - rz));
  //   const ratio = Math.abs(delta) / Math.PI;
  //   const duration = 200 * (1 + ratio)
  //   const dz = this.size.h / 8 * (1 + ratio);
  //   const bounceDown = new TWEEN.Tween(this.model.position, this.tweens)
  //     .easing(TWEEN.Easing.Quadratic.In)
  //     .to({ z: 0 }, duration / 2)
  //   new TWEEN.Tween(this.model.position, this.tweens)
  //     .easing(TWEEN.Easing.Quadratic.Out)
  //     .to({ z: dz }, duration / 2)
  //     .chain(bounceDown)
  //     .start();
  //   new TWEEN.Tween(this.model.rotation, this.tweens)
  //     .easing(TWEEN.Easing.Quadratic.Out)
  //     .to({ z: rz + delta }, duration)
  //     .start();  
  //   await this.tweens.promise();
  // }

  update() {
    super.update();
    if (this.introAnim) this.introAnim.update();
    if (this.ring) this.ring.update();
  }
}