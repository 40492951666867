import { LineSegments } from 'three/src/objects/LineSegments.js';
import { LineBasicMaterial } from 'three/src/materials/LineBasicMaterial.js';
import { Float32BufferAttribute } from 'three/src/core/BufferAttribute.js';
import { BufferGeometry } from 'three/src/core/BufferGeometry.js';
import { Color } from 'three/src/math/Color.js';

class GridHelper extends LineSegments {

  constructor(w = 10, h = 10, step = 1, color = 0x444444) {
    color = new Color(color);

    const vertices = [], colors = [];
    const w2 = w / 2, h2 = h / 2;
    let j = 0;
    const nw = Math.round(w / step);
    for (let i = 0; i <= nw; i++) {
      const k = -w2 + i / nw * 2 * w2;
      vertices.push(k, -h2, 0, k, h2, 0);
      color.toArray(colors, j); j += 3;
      color.toArray(colors, j); j += 3;
    }
    const nh = Math.round(h / step);
    for (let i = 0; i <= nh; i++) {
      const k = -h2 + i / nh * 2 * h2;
      vertices.push(- w2, k, 0, w2, k, 0);
      color.toArray(colors, j); j += 3;
      color.toArray(colors, j); j += 3;
    }

    const geometry = new BufferGeometry();
    geometry.setAttribute('position', new Float32BufferAttribute(vertices, 3));
    geometry.setAttribute('color', new Float32BufferAttribute(colors, 3));

    const material = new LineBasicMaterial({
      vertexColors: true,

      toneMapped: false,
      // transparent: true,
    });
    super(geometry, material);
    this.material = material;
    this.type = 'GridHelper';
  }

  setOpacity(opacity) {
    this.material.transparent = opacity < 1;
    this.material.opacity = opacity;
    this.material.needsUpdate = true;
  }
}


export default GridHelper;