import * as THREE from "three";
import GridHelper from "../components/GridHelper";
import TWEEN from '@tweenjs/tween.js';
import { PhaseType } from '../core/api';
import Laser from '../components/laser'
import Widget from './widget';
import { Howl } from 'howler';

const lightsaberSound = new Howl({
  src: [require('@/assets/sounds/lightsaber.wav')],
});
const humSound = new Howl({
  src: [require('@/assets/sounds/hum.mp3')],
});

export default class Track extends Widget {
  constructor(game) {
    super(game);
    this.group = new THREE.Group();
    const length = 3 * game.distance;
    // Plane
    const width = game.width;
    const planeGeom = new THREE.PlaneBufferGeometry(length, width, 2, 2);
    // planeGeom.rotateX(-Math.PI * 0.5);
    const planeMat = new THREE.MeshLambertMaterial({
      // color: 0xff00ee,
      color: 0x333333,
      side: THREE.DoubleSide,
    });
    const plane = new THREE.Mesh(planeGeom, planeMat);
    plane.receiveShadow = true;
    this.group.add(plane);

    this.grid = new GridHelper(length, width, 1);
    this.grid.position.z = 1e-3;
    this.grid.setOpacity(0);
    this.group.add(this.grid);

    const addCylinder = (dir, rad, outerRad, material) => {
      const cylGeom = new THREE.CylinderGeometry(rad, rad, length, 32);
      const cylinder = new THREE.Mesh(cylGeom, material);
      this.group.add(cylinder);
      cylinder.rotation.z = Math.PI / 2;
      cylinder.position.y = dir * (width / 2 + outerRad);
    }

    const addLaser = (dir, rad, outerRad) => {
      const y = dir * (width / 2 + outerRad);
      const laser = new Laser(this.group, new THREE.Vector3(0, y, 0), true);
      laser.setLength(length);
      laser.setRadius(rad);
      return laser;
    }

    // Add transparent cylinders
    const outerMat = new THREE.MeshBasicMaterial({
      color: 0xffffff,
      opacity: 0.5,
      transparent: true,
    });
    // Add glowing shape 
    // eslint-disable-next-line no-unused-vars
    this.col = 0xffff00;
    this.lasers = [];
    for (let k = -1; k < 2; k += 2) {
      const outerRad = width / 20;
      this.lasers.push(addLaser(k, outerRad, outerRad));
      addCylinder(k, outerRad, outerRad, outerMat);
    }

    // Add target strip
    const laser = new Laser(this.group, new THREE.Vector3(game.distance / 2, 0, 0), true);
    laser.setLength(width);
    laser.group.rotation.z = Math.PI / 2;
    laser.setRadius(0.1);
    this.lasers.push(laser);


    // Scale down and hide
    this.group.scale.x = 0;
    this.group.position.x = game.distance;
    this.group.visible = false;
    game.scene.add(this.group);
  }

  async animateIntro(duration) {
    await this.animateOpen(true, duration);
  }

  async animateOpen(open, duration) {
    lightsaberSound.play();
    let humId;
    if (open) humId = humSound.play();
    this.group.visible = true;
    const animGrid = new TWEEN.Tween({ o: open ? 0 : 1 }, this.tweens)
      .to({ o: open ? 1 : 0 }, Math.min(400, duration))
      .onUpdate(({ o }) => this.grid.setOpacity(o));
    if (!open) {
      animGrid.start();
      await this.tweens.promise();
    }
    // Scale
    new TWEEN.Tween(this.group.scale, this.tweens)
      .to({ x: open ? 1 : 0 }, duration)
      .start();
    // Move
    new TWEEN.Tween(this.group.position, this.tweens)
      .to({ x: open ? this.game.distance / 2 : this.game.distance }, duration)
      .start();
    await this.tweens.promise();
    // 
    if (open) {
      animGrid.start();
      await this.tweens.promise();
    }
    if (humId) humSound.fade(1.0, 0.0, 5000, humId)
    if (!open) this.group.visible = false;
  }

  onNewPhase(phase) {
    if (phase == PhaseType.PRUNE)
      this.lasers.forEach(laser => laser.animateColor(0xcc3333));
    else if (phase == PhaseType.DONE)
      this.animateOpen(false, 2000);
  }

  onReady() {
    this.lasers.forEach(laser => laser.animateColor(0x33cc33));
  }

  onCareful() {
    this.lasers.forEach(laser => laser.animateColor(0xcc8f33));
  }

  update() {
    super.update();
    this.lasers.forEach(laser => laser.update());
  }
}