import API, { PhaseType } from './api'

export default class Bot {
  constructor(playerId) {
    this.playerId = playerId;
    this.api = new API(playerId);
    this.api.setMessageListener(msg => this.onMessage(msg));
    this.jumpTarget = 0;
    this.jumpPressed = false;
    this.msg = {};
    this.init();
  }

  async init() {
    await this.api.join();
    await this.api.ready();
  }

  _setJumpPressed(pressed) {
    if (this.jumpPressed == pressed) return;
    this.jumpPressed = pressed;
    this.api.setJumpPressed(pressed);
  }

  onMessage(msg) {
    // Jump randomly
    const player = msg.players[this.playerId];
    if (!player) return;
    const newPhase = this.msg.phase != msg.phase;
    if (newPhase && msg.phase == PhaseType.PRUNE) {
      // Sample random jump count
      this.jumpTarget = Math.ceil(3 + Math.random() * 6);
      this._setJumpPressed(true);
    }
    if (msg.phase == PhaseType.RUN && player.jumpCount >= this.jumpTarget) {
      this._setJumpPressed(false);
    }
    this.msg = msg;
  }
}