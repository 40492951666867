<template lang='pug'>
.background(style="width: 100vw; height: 100vh; position: relative")
  canvas(ref="canvas", style="width: 100vw; height: 100vh")
  Overlay(ref="overlay", style="position: absolute; top: 0px; left: 0px")
</template>

<script>
import * as THREE from "three";
import Overlay from "./Overlay.vue";
import Game from "../js/game.js";

export default {
  components: {
    Overlay,
  },

  props: {},

  data: () => ({
    scene: null,
    camera: null,
    renderer: null,
    animFrame: null,
    // Objects
    track: null,
  }),

  destroyed() {
    if (this.animFrame) cancelAnimationFrame(this.animFrame);
    this.game.destroy();
  },

  mounted() {
    const canvas = this.$refs.canvas;
    this.scene = new THREE.Scene();

    this.renderer = new THREE.WebGLRenderer({
      antialias: true,
      canvas,
      alpha: true,
    });
    this.renderer.setPixelRatio( window.devicePixelRatio );
    // this.renderer.setClearColor(0x000000, 0); // the default
    // this.renderer.setClearColor( 0xffffff, 0);
    // this.scene.background = new THREE.Color( 0xff0000 );
    // this.renderer.shadowMap.enabled = true;

    // this.scene.background = new THREE.Color(0x222222);
    // this.scene.fog = new THREE.Fog(this.scene.background, 100, 150);

    // Light
    // const ambientLight = new THREE.AmbientLight(0xffffff, 1);
    // this.scene.add(ambientLight);

    const dirLight1 = new THREE.DirectionalLight(0xffffff, 1.5);
    dirLight1.position.set(1, 0.8, 0.6);
    this.scene.add(dirLight1);
    const dirLight2 = new THREE.DirectionalLight(0xffffff, 1.5);
    dirLight2.position.set(-1, -0.8, -0.6);
    this.scene.add(dirLight2);

    // dirLight.shadow.camera.near = 0.1;
    // dirLight.shadow.camera.far = 200;
    // dirLight.shadow.mapSize.width = 1024;
    // dirLight.shadow.mapSize.height = 1024;
    // dirLight.shadow.radius = 2;

    // dirLight.target.position.set(-1, 0, 9);

    // Create game
    this.game = new Game(canvas, this.scene, this.$refs.overlay);

    // Render
    this.render();
  },

  methods: {
    render() {
      this.resizeIfNeeded();
      this.game.update();
      this.renderer.render(this.scene, this.game.camera.getCamera());
      this.animFrame = requestAnimationFrame(this.render);
    },

    resizeIfNeeded() {
      const canvas = this.renderer.domElement;
      const width = canvas.clientWidth;
      const height = canvas.clientHeight;
      const needResize = canvas.width !== width || canvas.height !== height;
      if (needResize) {
        this.renderer.setSize(width, height, false);
        if (this.game.camera) this.game.camera.resize(canvas);
      }
    },
  },
};
</script>

<style scoped>
.background {
  background-image: radial-gradient(
      circle at 95% 15%,
      rgba(230, 38, 211, 0.2) 0%,
      rgba(230, 38, 211, 0.1) 10%,
      transparent 20%
    ),
    linear-gradient(to right top, #000000, #140009, #1b0017, #1b0025, #090036);
}
</style>
