import * as THREE from 'three'
import { TWEEN, TweenParent } from '../utils/tween'

export default class Laser extends TweenParent {

  constructor(parent, position, center = false, colorHex = null) {
    super();
    this.pos = position;
    this.group = new THREE.Group();
    const canvas = this.generateLaserBodyCanvas();
    const texture = new THREE.Texture(canvas);
    texture.needsUpdate = true;
    this.colorHex = colorHex || 0x4444aa;

    //texture
    this.material = new THREE.MeshLambertMaterial({
      map: texture,
      blending: THREE.AdditiveBlending,
      color: this.colorHex,
      side: THREE.DoubleSide,
      depthWrite: false,
      transparent: true
    });
    const geometry = new THREE.PlaneGeometry(1, 1);

    // use planes to simulate laserbeam
    const nPlanes = 15;
    for (let i = 0; i < nPlanes; i++) {
      var mesh = new THREE.Mesh(geometry, this.material);
      mesh.position.x = center ? 0 : 1 / 2;
      mesh.rotation.x = i / nPlanes * 2 * Math.PI;
      this.group.add(mesh);
    }
    // 
    this.group.position.copy(this.pos);
    // Now stretch laser to 
    parent.add(this.group);
  }

  setVisibility(visible) {
    this.group.visible = visible;
  }

  setRadius(radius) {
    this.group.scale.y = 2 * radius;
    this.group.scale.z = 2 * radius;
  }

  setLength(length) {
    this.group.scale.x = length;
  }

  animateColor(colorHex) {
    if (this.colorHex == colorHex) return;
    const initCol = new THREE.Color(this.colorHex);
    const targetCol = new THREE.Color(colorHex);
    this.colorHex = colorHex;
    new TWEEN.Tween({ c: 0 }, this.tweens)
      .to({ c: 1 }, 300)
      .onUpdate(({ c }) => {
        const col = initCol.lerp(targetCol, c);
        this.material.color = col;
        this.material.needsUpdate = true;
      })
      .start();
  }

  async animateShoot(target, duration) {
    this.tweens.removeAll();
    const pos = this.group.getWorldPosition(new THREE.Vector3())
    const dpos = pos.sub(target);
    this.group.visible = true;
    this.group.rotation.y = -Math.atan2(dpos.z, dpos.x);
    this.group.rotation.z = Math.atan2(dpos.y, dpos.x);
    this.group.scale.x = 0;
    const length = dpos.length();
    new TWEEN.Tween({ t: 0 }, this.tweens)
      .to({ t: 1 }, duration)
      .onUpdate(({ t }) => {
        const scale = Math.min(t * 1.5, 1);
        this.group.scale.x = -length * scale;
        if (t == 1) this.group.visible = false;
      })
      .start();
    await this.tweens.promise();
  }

  generateLaserBodyCanvas() {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = 1;
    canvas.height = 64;
    const gradient = context.createLinearGradient(0, 0, canvas.width, canvas.height);
    gradient.addColorStop(0, 'rgba(  0,  0,  0,0.0)');
    gradient.addColorStop(0.1, 'rgba(160,160,160,0.3)');
    gradient.addColorStop(0.5, 'rgba(255,255,255,0.5)');
    gradient.addColorStop(0.9, 'rgba(160,160,160,0.3)');
    gradient.addColorStop(1.0, 'rgba(  0,  0,  0,0.0)');
    context.fillStyle = gradient;
    context.fillRect(0, 0, canvas.width, canvas.height);
    return canvas;
  }
}