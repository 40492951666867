import * as THREE from "three";
import { normalizeMesh } from "../utils/utils";
import { TweenParent, TWEEN } from '../utils/tween';

const vertexShader = `
uniform float amplitude;
attribute vec3 displacement;
attribute vec3 customColor;
varying vec3 vColor;

void main() {
  vec3 newPosition = position + amplitude * displacement;
  vColor = customColor;
  gl_Position = projectionMatrix * modelViewMatrix * vec4( newPosition, 1.0 );
}
`;

const fragmentShader = `
uniform vec3 color;
uniform float opacity;
varying vec3 vColor;
void main() {
  gl_FragColor = vec4( vColor * color, opacity );
}
`;

const UNIFORM_OPACITY = 0.3;

export default class WireframeAnim extends TweenParent {
  constructor(parent, model) {
    super();
    this.parent = parent;
    this.model = model;
    const mesh = model.children[0];
    const geometry = mesh.geometry;
    this.uniforms = {
      amplitude: { value: 5.0 },
      opacity: { value: UNIFORM_OPACITY },
      color: { value: new THREE.Color(0xffffff) },
    };
    const material = new THREE.ShaderMaterial({
      uniforms: this.uniforms,
      vertexShader,
      fragmentShader,
      blending: THREE.AdditiveBlending,
      depthTest: false,
      transparent: true,
    });
    const count = geometry.attributes.position.count;
    const displacement = new THREE.Float32BufferAttribute(count * 3, 3);
    geometry.setAttribute("displacement", displacement);
    const customColor = new THREE.Float32BufferAttribute(count * 3, 3);
    geometry.setAttribute("customColor", customColor);
    const color = new THREE.Color(0xffffff);
    for (let i = 0, l = customColor.count; i < l; i++) {
      color.setHSL(i / l, 0.5, 0.5);
      color.toArray(customColor.array, i * customColor.itemSize);
    }
    const line = new THREE.Line(geometry, material);
    this.mesh = normalizeMesh(line);
    this.mesh.position.y = model.position.y;
    this.parent.add(this.mesh);
    this.model.visible = false;
    this.mesh.visible = false;

    // Shuffle the displacements
    const attributes = line.geometry.attributes;
    const array = attributes.displacement.array;
    for (let i = 0, l = array.length; i < l; i += 3) {
      array[i] = 0.5 - Math.random();
      array[i + 1] = 0.5 - Math.random();
      array[i + 2] = 0.5 - Math.random();
    }
    attributes.displacement.needsUpdate = true;
  }

  async animate(duration) {
    this.mesh.visible = true;
    this.model.visible = true;
    // Twist
    new TWEEN.Tween({ s: 0 }, this.tweens)
      .to({ s: 1 })
      .duration(duration / 3)
      .easing(TWEEN.Easing.Cubic.Out)
      .onUpdate(({ s }) => {
        this.mesh.scale.setScalar(s)
        this.model.scale.setScalar(s)
      })
      .start();
    const factor = 0.2;
    new TWEEN.Tween({ t: UNIFORM_OPACITY }, this.tweens)
      .to({ t: 0 })
      .delay(duration * (1 - factor))
      .duration(duration * factor)
      .onUpdate(({ t }) => {
        this.uniforms.opacity.value = t;
      })
      .start();
    new TWEEN.Tween({ t: 1 }, this.tweens)
      .to({ t: 0 })
      .duration(duration)
      .easing(TWEEN.Easing.Cubic.Out)
      .onUpdate(({ t }) => {
        this.uniforms.amplitude.value = t * 10;
      })
      .start();
    await this.tweens.promise();
    this.parent.remove(this.mesh);
  }
}


