import Vue from 'vue'
import VueRouter from 'vue-router'
import GameView from '../views/GameView.vue'
import DevView from '../views/DevView.vue'
import ServerView from '../views/ServerView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: GameView
  },
  {
    path: '/server',
    component: ServerView
  },
  {
    path: '/dev',
    component: DevView
  },
]

const router = new VueRouter({
  routes
})

export default router
