
import { WalletDisconnectButton, WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { useWallet } from '@solana/wallet-adapter-react';
import React, { FC } from 'react';

let prevWallet = null;

const Navigation = (props) => {
  const wallet = useWallet();

  const prevKey = prevWallet &&
    prevWallet.publicKey &&
    prevWallet.publicKey.toBase58();
  const newKey = wallet &&
    wallet.publicKey &&
    wallet.publicKey.toBase58();
  if (prevKey != newKey) {
    props.onWallet(wallet);
    prevWallet = wallet;
  }
  const style = {
    color: 'white',
    background: '#FF4081',
  }
  return (
    <WalletMultiButton style={style} />
  );
};

export default Navigation;