<template lang="pug">
div(style="width: 100%; height: 100%; pointer-events: none; user-select: none")
  //- 
  Animated.full-col(v-if="overlay == 'begin'", ref="beginOverlay")
    .full-col(
      style="background: #000; justify-content: center; align-items: center"
    )
      Animated(blink)
        .nasa-text.text.clickable(
          @click="begin",
          style="margin-top: 20px; font-size: 40px"
        ) Enter
  //- 
  Animated.full-col(v-if="overlay == 'menu'", ref="menuOverlay", hide)
    //- Top 
    .flex-row
      v-btn.clickable.ma-2(icon, @click="toggleSound")
        v-icon(color="#fffa") {{ sound ? 'mdi-volume-high' : 'mdi-volume-off' }}
    //- 
    TitleText(style="width: 100%; height: 30%")
    v-spacer
    //- Bottom
    .flex-col(style="width: 100%; padding: 64px")
      Animated(blink)
        .nasa-text.text.clickable(
          @click="startPressed",
          style="font-size: 60px"
        ) Start
  //- Game overlay
  Animated.full-col(v-if="overlay == 'game'", ref="gameOverlay", hide)
    //- Top 
    .flex-row.pa-3(style="align-items: flex-start")
      .flex-col(style="align-items: flex-start")
        .nasa-text.text(style="font-size: 25px") {{ info }}
        .nasa-text.text(style="font-size: 18px") Distance: {{ distance.toFixed(1) }}
        .nasa-text.text(style="font-size: 18px") Position: {{ position }}/{{ playerCount }}
        .nasa-text.text(style="font-size: 18px") Jumps: {{ jumpCount }}
        .nasa-text.text(style="font-size: 18px") Multiplier: {{ multiplier.toFixed(1) }}x
      v-spacer
      .flex-col(style="align-items: flex-end")
        //- .nasa-text.text(style="font-size: 18px") Multiplier:
        .nasa-text.text(
          v-if="time > 0",
          style="font-size: 18px; text-align: right"
        ) {{ time.toFixed(1) }}s
        //-   
        .nasa-text.text.grey--text(
          v-for="(time, idx) in timeHist",
          :key="`time_${idx}`",
          :style="{ 'font-size': '18px', opacity: Math.max(0, Math.min(1, (5 - idx) / 3)) }"
        ) {{ time.toFixed(1) }}s

    v-spacer
    //- Bottom
    .flex-col(style="width: 100%; padding: 32px; align-items: center")
      Animated(blink)
        .nasa-text.text(v-if="showTutorial && !tutorialDone") {{ isMobile() ? 'hold jump button' : 'hold spacebar' }}
      //- 
      .btn-style.mt-4(v-if="isMobile()", ref="jumpBtn")
        v-icon(:size="60", color="white") mdi-chevron-up

  //- End overlay
  Animated.full-col(v-if="overlay == 'end'", ref="endOverlay", hide)
    .full-col(
      style="background: #000; justify-content: center; align-items: center"
    )
      .nasa-text.text(style="font-size: 40px") Finished
      .nasa-text.text(style="font-size: 50px") {{ position }}/{{ playerCount }}
      Animated(blink)
        .nasa-text.text.clickable(
          @click="reload",
          style="margin-top: 10px; font-size: 40px"
        ) Restart
</template>

<script>
import Animated from "@/components/Animated";
import TitleText from "@/components/TitleText";
import { PhaseType } from "@/js/core/api";
import Touch from "@/js/utils/touch.js";
import { Howl, Howler } from "howler";
import { isMobile, isFullscreen, setFullscreen } from "@/js/utils/utils";

const beepSound = new Howl({
  src: [require("@/assets/sounds/beep.wav")],
});

export default {
  components: {
    Animated,
    TitleText,
  },

  data: () => ({
    sound: true,
    //
    touch: null,
    game: null,
    overlay: "begin",
    gameStarted: false,
    // Overlay data
    msg: {},
    info: null,
    phaseStart: 0,
    distance: 0,
    jumpCount: 0,
    playerCount: 0,
    position: 0,
    time: 0,
    timeHist: [],
    multiplier: 1,
    // Tutorial
    jumpPressed: false,
    tutorialDone: false,
    showTutorial: false,
    jumpTime: 0,
    // Misc
    isMobile,
    timerPrev: 0,
    // timeHist: [2.4, 5.3, 9.1, 4.5, 2.4, 5.3, 9.1, 4.5],
    //
  }),

  created() {},

  mounted() {
    document.body.onkeydown = (e) => {
      if (e.keyCode != 32) return;
      this._setJumpPressed(true);
    };
    document.body.onkeyup = (e) => {
      if (e.keyCode != 32) return;
      this._setJumpPressed(false);
    };
    document.onfullscreenchange = function (event) {
      console.log("FULL SCREEN CHANGE", event);
    };
    // this.startGame();

    // TEMP
    // this._setOverlay("game");
    // console.log("set game");
  },

  destroy() {
    if (this.touch) this.touch.destroy();
  },

  computed: {},

  methods: {
    toggleSound() {
      if (this.sound) {
        this.sound = false;
        Howler.volume(0);
      } else {
        this.sound = true;
        Howler.volume(1);
      }
    },

    reload() {
      window.location.reload(true);
      // this.$router.go(this.$router.currentRoute);
    },

    startPressed() {
      if (!this.gameStarted) this.startGame();
    },

    async _setOverlay(overlay) {
      this.overlay = overlay;
      await new Promise((resolve) => this.$nextTick(resolve));
    },

    async begin() {
      if (isMobile()) setFullscreen(true);
      this.game.playIntroTrack();
      await this.$refs.beginOverlay.animateShow(false);
      await this._setOverlay("menu");
      await this.$refs.menuOverlay.animateShow(true);
    },

    async startGame() {
      // setFullscreen();
      this.gameStarted = true;
      await this.$refs.menuOverlay.animateShow(false);
      this.game.init();
    },

    _setJumpPressed(pressed) {
      if (this.jumpPressed == pressed) return;
      this.jumpPressed = pressed;
      // Handle tutorial
      if (pressed) this.jumpTime = Date.now();
      if (!pressed && Date.now() - this.jumpTime > this.msg.jumpDuration)
        this.tutorialDone = true;
      //
      this.game.setJumpPressed(pressed);
      if (this.$refs.jumpBtn)
        this.$refs.jumpBtn.style.background = pressed ? "#fff3" : "#0000";
    },

    async showGameOverlay() {
      await this._setOverlay("game");
      // Setup touch & buttons
      this.touch = new Touch((dx, dy) => this.onDrag(dx, dy));
      await this.$refs.gameOverlay.animateShow(true);
      if (this.$refs.jumpBtn) {
        this.$refs.jumpBtn.addEventListener("pointerdown", (e) => {
          e.preventDefault();
          this._setJumpPressed(true);
        });
        this.$refs.jumpBtn.addEventListener("pointerup", (e) => {
          e.preventDefault();
          this._setJumpPressed(false);
        });
        this.$refs.jumpBtn.addEventListener("pointercancel", (e) => {
          e.preventDefault();
          this._setJumpPressed(false);
        });
      }
    },

    onDrag(dx, dy) {
      this.game.camera.onDrag(dx, dy);
    },

    async showEndOverlay() {
      await this.$refs.gameOverlay.animateShow(false);
      await this._setOverlay("end");
      await this.$refs.endOverlay.animateShow(true);
    },

    setGame(game) {
      this.game = game;
    },

    onMessage(msg) {
      this.msg = msg;
    },

    onNewPhase(phase) {
      this.phaseStart = Date.now();
      if (phase == PhaseType.INIT) {
        this.info = "Init";
      } else if (phase == PhaseType.WAIT) {
        this.info = "Wait";
      } else if (phase == PhaseType.PRUNE) {
        if (this.time > 0) {
          this.timeHist.splice(0, 0, this.time);
          this.timeHist.splice(10, 1);
        }
      } else if (phase == PhaseType.RUN) {
        this.info = "Run";
      } else if (phase == PhaseType.DONE) {
        this.info = "Done";
        setTimeout(() => {
          this.showEndOverlay();
        }, 5000);
      }
    },

    onCareful() {
      this.showTutorial = false;
      this.info = "Careful";
    },

    onReady() {
      this.showTutorial = true;
    },

    destroy() {
      // -
    },

    // _timeTarget() {
    //   const safeDuration = this.msg.safeDuration;
    //   const L = this.msg.curve.L;
    //   const t = Date.now() - this.phaseStart - safeDuration;
    //   if (this.msg.phase != PhaseType.RUN) return 0;
    //   if (t < 0) return 1;
    //   const tSec = t / 1e3;
    //   return 1 + (Math.exp(L * tSec) - 1);
    // },

    updateVars() {
      const playerId = this.game.playerId;
      const player = this.msg.players
        ? this.msg.players[this.game.playerId]
        : null;
      //
      const character = this.game.characters[playerId];
      if (!character || !character.model || !player) return;
      // Update distance
      const distTarget = Math.max(
        0,
        this.msg.distance - character.model.position.x
      );
      // if (distTarget <= 0) console.log("DONE IN", Date.now() - this.phaseStart);
      this.distance += (distTarget - this.distance) * 0.1;
      // Update time
      if (this.msg.phase == PhaseType.RUN) {
        this.time = (Date.now() - this.phaseStart) / 1000;
      } else {
        this.time = 0;
      }

      // Update jumpCount
      this.jumpCount = player.jumpCount;
      this.playerCount = Object.keys(this.msg.players).length;

      // Update multiplier
      const multTarget = Math.max(
        1,
        player.nextJumpDistance / this.msg.safeJumpDistance
      );
      this.multiplier += (multTarget - this.multiplier) * 0.2;

      //
      if (!player.arrived) {
        const phasePos = player.phasePos;
        this.position = Object.values(this.msg.players).filter(
          (player) => player.phasePos >= phasePos
        ).length;
      } else {
        this.position = player.arrived;
      }
    },

    update() {
      const pruneDuration = this.msg.pruneDuration || 0;
      const dt = this.phaseStart + pruneDuration - Date.now();
      if (this.msg.phase == PhaseType.PRUNE && dt > 0) {
        const timer = Math.floor(dt / 1000 + 1);
        if (timer > this.timerPrev) this.timerPrev = timer;
        if (timer < this.timerPrev) {
          this.timerPrev = timer;
          if (timer < 4) beepSound.play();
        }
        const timerStr = timer.toFixed(0);
        this.info = timer > 3 ? `Wait ${timerStr}s` : `Ready ${timerStr}s`;
      }
      // Smooth distance
      this.updateVars();
    },
  },
};
</script>

<style scoped>
.clickable {
  pointer-events: auto;
  cursor: pointer;
}

.btn-style {
  align-self: flex-end;
  display: flex;
  justify-content: center;
  align-items: center;
  width: min(25vw, 250px) !important;
  height: min(25vw, 250px) !important;
  border: 2px solid white;
  border-radius: 50%;
  pointer-events: auto;
}

.full-col {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.text {
  color: white;
}
</style>
