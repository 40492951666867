import * as THREE from 'three'
import TWEEN from '@tweenjs/tween.js';
import { PhaseType } from '../core/api';
import Laser from '../components/laser'
import { loadPetModel } from '../utils/utils'
import WireframeAnim from '../components/wireframeAnim';
import Ring from '../components/ring';
import Widget from './widget';

import { Howl } from 'howler';

const shootSound = new Howl({
  src: [require('@/assets/sounds/laser_shoot.wav')],
});

export const KEEPER_POS = (new THREE.Vector3(4.85, 0, 1)).normalize();
const SCALE = 100;

export default class Keeper extends Widget {
  constructor(game) {
    super(game);
    // 
    this.group = new THREE.Group();
    this.group.position.copy(KEEPER_POS.clone().multiplyScalar(800)); // TODO: outsource skybox radius

    // Add laser
    const laserPos = new THREE.Vector3();
    this.laser = new Laser(this.group, laserPos);
    this.laser.group.position.z = SCALE * 0.35;
    this.laser.setVisibility(false);

    // Add group
    this.runColor = new THREE.Color().setHSL(0.5, 1.0, 0.5);
    game.scene.add(this.group);
    this.load();
  }

  async load() {
    const url = `/data/keeper.vox`;
    this.model = await loadPetModel(url);
    this.model.scale.setScalar(SCALE);
    this.model.children[0].geometry.center();
    this.model.children[0].position.set(0, 0, 0)
    this.model.children[0].rotation.y = Math.PI;
    this.model.rotation.order = 'ZYX';
    // Add ring
    this.ring = new Ring(this.model, .5, .05, new THREE.Color(0xffffff), true)
    this.ring.group.position.z = -.5;
    this.ring.animateTiles(this.runColor, new THREE.Color(0xffffff));
    // Add keeper
    this.group.add(this.model);
    // Load anim
    // this.introAnim = new WireframeAnim(this.group, this.model);
  }

  async animateIntro(duration) {
    // if (!this.introAnim) return console.error('Model not loaded')
    // await this.introAnim.animate(duration);
  }

  async onNewPhase(phase) {
    if (phase == PhaseType.RUN)
      await this.animateRot(0, Math.random() < .5 ? -Math.PI / 2 : Math.PI / 2)
    else if (phase == PhaseType.PRUNE)
      await this._prune();
  }

  onReady() {
    this.ring.animateTiles(this.runColor, new THREE.Color(0xffffff));
  }

  onCareful() {
    this.ring.animateTiles(new THREE.Color(0xf5bd07), new THREE.Color(0xffffff));
  }

  async _prune() {
    if (!this.msg.players) return;
    this.ring.animateTiles(new THREE.Color(0xcc3333), new THREE.Color(0xffffff));
    const yTarget = -Math.atan2(KEEPER_POS.z, KEEPER_POS.x);
    const zTarget = Math.atan2(KEEPER_POS.y, KEEPER_POS.x);
    await this.animateRot(yTarget, zTarget)
    const pruneIds = [];
    const playerIds = Object.keys(this.msg.players);
    for (let k = 0; k < playerIds.length; k++) {
      const id = playerIds[k];
      const player = this.msg.players[id];
      if (!player.pruned) continue;
      pruneIds.push(id);
    }
    // Compute delay
    const pruneDuration = Math.min(500, (this.msg.pruneDuration - 1e3) / playerIds.length);
    for (let k = 0; k < pruneIds.length; k++) {
      const id = pruneIds[k];
      const character = this.game.characters[id];
      setTimeout(() => shootSound.play(), 300);
      await this.laser.animateShoot(character.model.position, pruneDuration);
      character.prune();
    }
  }

  async animateRot(y, z, duration = 1000) {
    this.tweens.removeAll();
    new TWEEN.Tween(this.model.rotation, this.tweens)
      .to({ y, z }, duration)
      .easing(TWEEN.Easing.Quadratic.InOut)
      .start();
    await this.tweens.promise();
  }

  update() {
    super.update();
    this.laser.update();
    if (this.ring) this.ring.update();
  }
}