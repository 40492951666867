<template lang="pug">
.background(style="width: 1500px; height: 500px")
  div(style='width: 100%')
    //- Top 
    TitleText(style="width: 1500px; height: 20%; margin-top: 85px")
  .flex-row(style="justify-content: center")
    .subtitle COMING SOON
</template>

<script>
import TitleText from "@/components/TitleText";

export default {
  components: {
    TitleText,
  },
};
</script>

<style scoped>
.background {
  background-image: radial-gradient(
      circle at 95% 15%,
      rgba(230, 38, 211, 0.2) 0%,
      rgba(230, 38, 211, 0.1) 10%,
      transparent 20%
    ),
    linear-gradient(to right top, #000000, #140009, #1b0017, #1b0025, #090036);
}

.subtitle {
  font-family: "Sofia Pro", sans-serif;
  color: white;
  font-size: 30px;
  letter-spacing: 0.5rem;
  margin-top: 300px
  /* text-align: center; */
}
</style>
