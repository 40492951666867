<template lang="pug">
.flex-col.pa-2
  v-btn(@click="createRoom", :disabled="!!room")
    | {{ room ? `In room ${room.name}` : 'Create or join room' }}
  v-btn.mt-2(@click="proveWallet", :disabled="!wallet")
    | Prove wallet
  WalletButton.mt-2(ref="wallet", :onWallet="onWallet")
  //- 
  Lobby.mt-2
</template>

<script>
// Colyseus
import * as Colyseus from "colyseus.js"; // not necessary if included via <script> tag.
const client = new Colyseus.Client("ws://localhost:2567");
// Wallet
import WalletButton from "@/components/wallet/WalletButton.jsx";
import Lobby from "@/components/lobby/Lobby";
import { prove } from "@identity.com/prove-solana-wallet";

export default {
  components: {
    WalletButton,
    Lobby,
  },

  data: () => ({
    room: null,
    wallet: null,
  }),

  mounted() {
    this.createRoom();
  },

  methods: {
    onWallet(wallet) {
      this.wallet = wallet;
      console.log("on wallet", wallet);
    },

    async proveWallet() {
      const signer = (transaction) => this.wallet.signTransaction(transaction);
      console.log("keypair", this.wallet.publicKey.toBase58());
      const proof = await prove(this.wallet.publicKey, signer);
      console.log("sending publickey", this.wallet.publicKey.toBase58(), proof);
      //
      this.room.send("setPublicKey", {
        publicKey: this.wallet.publicKey.toBase58(),
        proof,
      });
    },

    async createRoom() {
      if (this.room) return;
      try {
        this.room = await client.joinOrCreate("lobby");
      } catch (e) {
        console.error("Join error", e);
      }
      // Subscribe to state
      this.room.onStateChange((state) => {
        console.log(this.room.name, "has new state:", state);
      });
      this.room.onError((code, message) => {
        console.log(client.id, "couldn't join", this.room.name);
      });
      this.room.onLeave((code) => {
        console.log(client.id, "left", this.room.name);
      });
    },
  },
};
</script>

<style scoped>
</style>
