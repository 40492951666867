import { isMobile } from './utils'

export default class Touch {
  constructor(onDrag) {
    this.onDrag = onDrag;
    this.touchPrev = null;
    this.dragging = false;
    // Handle touches

    if (!isMobile()) {
      document.addEventListener(
        "pointerdown", (e) => this.pointerdown(e),
        { passive: false });
      document.addEventListener(
        "pointermove", (e) => this.pointermove(e),
        { passive: false });
      document.addEventListener(
        "pointerup", (e) => this.pointerup(e),
        { passive: false });
      document.addEventListener(
        "pointercancel", (e) => this.pointerup(e),
        { passive: false });
    } else {
      document.addEventListener(
        "touchstart", (e) => this.pointerdown(e),
        { passive: false });
      document.addEventListener(
        "touchmove", (e) => this.pointermove(e),
        { passive: false });
      document.addEventListener(
        "touchend", (e) => this.pointerup(e),
        { passive: false });
      document.addEventListener(
        "touchcancel", (e) => this.pointerup(e),
        { passive: false });
    }
  }

  pointerdown(e) {
    e.preventDefault();
    this.touchPrev = e.touches ? e.touches[0] : e;
    this.dragging = true;
  }

  pointermove(e) {
    e.preventDefault();
    if (!this.dragging) return;
    e = e.touches ? e.touches[0] : e;
    const dx = e.clientX - this.touchPrev.clientX;
    const dy = e.clientY - this.touchPrev.clientY;
    this.touchPrev = e;
    this.onDrag(dx, dy);
  }

  pointerup(e) {
    e.preventDefault();
    this.dragging = false;
  }

  destroy() {
    if (!isMobile()) {
      document.removeEventListener('pointerdown', this.pointerdown)
      document.removeEventListener('pointermove', this.pointermove)
      document.removeEventListener('pointerup', this.pointerup)
      document.removeEventListener('pointercancel', this.pointercancel)
    } else {
      document.removeEventListener('touchstart', this.pointerdown)
      document.removeEventListener('touchmove', this.pointermove)
      document.removeEventListener('touchend', this.pointerup)
      document.removeEventListener('touchcancel', this.pointercancel)
    }
    // 
    // document.removeEventListener('pointerdown', this.pointerdown)
    // document.removeEventListener('pointercancel', this.pointermove)
    // document.removeEventListener('pointermove', this.pointerup)
  }
}