// Mock server
import lobby from './lobby'

export class PhaseType {
  static INIT = 0;
  static WAIT = 1;
  static RUN = 2;
  static PRUNE = 3;
  static DONE = 4;
}

export default class API {
  constructor(playerId) {
    this.playerId = playerId;
    this.messageListener = null;
  }

  // Game API
  async join() {
    const socket = {
      send: async (msg) => {
        this.onMessage(msg)
      }
    }
    await lobby.join(this.playerId, socket);
  }

  async ready() {
    await lobby.ready(this.playerId);
  }

  async setJumpPressed(pressed) {
    await lobby.setJumpPressed(this.playerId, pressed);
  }

  async reset() {
    await lobby.reset();
  }

  destroy() {
    lobby.destroy();
  }

  setMessageListener(listener) {
    this.messageListener = listener;
  }

  onMessage(msg) {
    if (this.messageListener) this.messageListener(msg);
  }
}