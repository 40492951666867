import * as THREE from 'three';
import { TweenParent, TWEEN } from '../utils/tween';
import { Line2 } from 'three/examples/jsm/lines/Line2.js';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial.js';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry.js';
import { PhaseType } from '../core/api';
import createGlowMaterial from '../components/glowMaterial';
import Widget from './widget';

const COLORS = [
  new THREE.Color(0xf2385a),
  new THREE.Color(0xf5a503),
  new THREE.Color(0xe9f1df),
  new THREE.Color().setHSL(0.5, 1.0, 0.5),
  new THREE.Color(0x56d9cd),
  new THREE.Color(0x3aa1bf)];

export default class Gate extends Widget {
  constructor(game) {
    super(game);
    this.group = new THREE.Group();
    this.group.position.x = game.distance;

    // Build gate
    this.radius = this.game.width * .9;
    const geometry = new THREE.TorusGeometry(this.radius, 0.2, 16, 100);
    this.initColor = (new THREE.Color()).setHSL(0.5, 1.0, 0.5);
    this.redColor = (new THREE.Color()).setHSL(1.0, 1.0, 0.5);
    this.color = this.initColor.clone();
    this.uniforms = {
      s: { value: -1.0 },
      b: { value: 1.0 },
      p: { value: 1.0 },
      glowColor: { value: this.initColor }
    };
    this.material = createGlowMaterial(this.uniforms);
    // new THREE.MeshBasicMaterial({ color: this.color });
    this.mesh = new THREE.Mesh(geometry, this.material);
    this.mesh.rotation.y = Math.PI / 2;
    this.mesh.visible = false;
    this.group.add(this.mesh);
    game.scene.add(this.group);
  }

  animateIntro(duration) {
    this.gateIntro = new GateIntro(this.group, this.radius);
    this.gateIntro.animate(duration);
  }

  async onNewPhase(phase) {
    super.onNewPhase(phase);
    if (phase == PhaseType.PRUNE)
      await this.animateColor(this.redColor, 400);
  }

  onReady() {
    this.animateColor(this.initColor, 400);
  }

  onCareful() {
    this.animateColor(new THREE.Color(0xf5bd07), 400);
  }

  async awaitIntro() {
    await this.gateIntro.terminate();
    this.gateIntro = null;
    this.mesh.visible = true;
  }

  async animateColor(color, duration) {
    if (this.color.getHex() == color.getHex()) return;
    this.tweens.removeAll();
    // Scale
    const col = new THREE.Color();
    new TWEEN.Tween({ t: 0 }, this.tweens)
      .to({ t: 1 })
      .duration(duration)
      .onUpdate(({ t }) => {
        col.lerpColors(this.color, color, t);
        this.uniforms.glowColor.value = col;
        // this.material.color = col;
        // this.material.needsUpdate = true;
        if (t == 1) this.color.copy(color);
      })
      .start();
  }

  animateGlow() {
    new TWEEN.Tween({ t: 1 }, this.tweens)
      .to({ t: 0.6 }, 2000)
      .onUpdate(({ t }) => {
        this.uniforms.b.value = t;
      })
      .yoyo(true)
      .repeat(Infinity)
      .start();
  }

  update() {
    super.update();
    if (this.gateIntro) this.gateIntro.update();
  }
}

class GateIntro extends TweenParent {
  constructor(parent, radius) {
    super();
    this.radius = radius;
    this.parent = parent;
    this.geometry = new LineGeometry();
    const material = new LineMaterial({
      vertexColors: true,
      linewidth: 0.01,
      // sizeAttenuation: true
    });
    this.spinner = new Line2(this.geometry, material);
    this.spinner.rotation.y = Math.PI / 2;
    parent.add(this.spinner);

    // 
    this.object = { rotation: 0, twist: 0, scale: 0 };
    this.repeat = true;
  }

  async terminate() {
    this.repeat = false;
    await this.tweens.promise();
    this.parent.remove(this.spinner);
  }

  animate(duration) {
    // if (duration) return;
    this.tweens.removeAll();
    // Reset properties
    this.object.rotation = 0;
    this.object.twist = 0;
    // Scale
    new TWEEN.Tween(this.object, this.tweens)
      .to({ scale: this.radius })
      .duration(duration / 4)
      .easing(TWEEN.Easing.Linear.None)
      .start();
    // Twist
    new TWEEN.Tween(this.object, this.tweens)
      .to({ twist: Math.PI * 4 })
      .duration(duration / 2)
      .easing(TWEEN.Easing.Cubic.InOut)
      .yoyo(true)
      .repeat(1)
      .start();
    // Rotation
    new TWEEN.Tween(this.object, this.tweens)
      .to({ rotation: Math.PI })
      .duration(duration)
      .easing(TWEEN.Easing.Linear.None)
      .onComplete(() => {
        if (this.repeat) this.animate(duration);
      })
      .start();
  }

  _getCol(color, t) {
    const len = COLORS.length;
    const id0 = Math.max(0, Math.min(len - 1, Math.floor(t * len)));
    const id1 = Math.min(id0 + 1, len - 1);
    return color.lerpColors(
      COLORS[id0],
      COLORS[id1],
      len * t - id0);
  }

  update() {
    super.update();
    this.spinner.rotation.y = Math.PI / 2 + this.object.rotation;
    this.spinner.scale.setScalar(this.object.scale);
    const color = new THREE.Color(); // "hsl(0, 100%, 70%)"
    const position = new THREE.Vector3();
    const axis = new THREE.Vector3(1, 0, 0);
    const positions = [];
    const colors = [];
    const numPoints = 1000;
    for (let i = 0; i < numPoints; i++) {
      const angle = i / numPoints * Math.PI * 2;
      position.set(Math.cos(angle), Math.sin(angle), 0);
      let f = Math.cos(angle);
      position.applyAxisAngle(axis, this.object.twist * f);
      positions[i * 3 + 0] = position.x;
      positions[i * 3 + 1] = position.y;
      positions[i * 3 + 2] = position.z;
      // color.setHSL(position.z * 0.5 + 0.5, 1.0, 0.5);
      const t = (position.z + 1) / 2;
      this._getCol(color, t);
      colors[i * 3 + 0] = color.r;
      colors[i * 3 + 1] = color.g;
      colors[i * 3 + 2] = color.b;
    }
    this.geometry.setPositions(positions);
    this.geometry.setColors(colors);
  }
}