import Character from "./widgets/character.js";
import API, { PhaseType } from "./core/api";
import Skybox from "./widgets/skybox.js";
import Track from "./widgets/track.js";
import Camera from "./widgets/camera.js";
import Gate from "./widgets/gate.js";
import Keeper from "./widgets/keeper.js";
import { Howler, Howl } from 'howler';
// Temp bots
import Bot from './core/bot';
Howler.volume(1); // TEMP

const MODEL_IDS = [...Array(18)].map((_, idx) => `${idx + 3}`);

// Music (move to music manager)
const introTrack = new Howl({
  src: [require('@/assets/soundtrack/intro_track.mp3')],
  html5: true,
  loop: true,
  volume: 0.5,
});
const tracks = [
  new Howl({
    src: [require('@/assets/soundtrack/track_1.mp3')],
    html5: true,
    loop: true,
    volume: 0.2,
  }),
  new Howl({
    src: [require('@/assets/soundtrack/track_2.mp3')],
    html5: true,
    loop: true,
    volume: 0.2,
  }),
];


// Main choregrapher class
export default class Game {
  constructor(canvas, scene, overlay) {
    this.canvas = canvas;
    this.scene = scene;
    this.overlay = overlay;
    // Parameters
    this.distance = 100; // TODO: remove
    this.width = 10; // TODO: remove
    this.characters = {};
    this.msg;

    // Create widgets
    this.skybox = new Skybox(this);
    this.keeper = new Keeper(this);
    this.gate = new Gate(this);
    this.camera = new Camera(this);
    this.track = new Track(this);

    this.widgets = [
      this.skybox,
      this.gate,
      this.camera,
      this.track,
      this.overlay,
      this.keeper];

    this.overlay.setGame(this);

    // Create API
    this.playerId = "3";
    this.modelOffset = Math.floor(Math.random() * MODEL_IDS.length);
    this.api = new API(this.playerId);
    this.api.setMessageListener(msg => this.onMessage(msg));

    // msg 
    this.msg = {};
    this.phaseTimeout = null;

    // this.init();
    this.join();
  }

  async join() {
    // Reset api
    await this.api.reset();
    // Intanciate bots
    this.bots = [];
    for (let k = 1; k <= 6; k++) {
      const id = `${k}`;
      if (id == this.playerId) await this.api.join();
      else this.bots.push(new Bot(`${k}`));
    }
    // Start intro
    this.gate.animateIntro(4000);
  }

  playIntroTrack() {
    // move to track manager
    Howler.stop();
    this.soundtrack = tracks[Math.floor(Math.random() * tracks.length)];
    this.introTrackId = introTrack.play();
  }

  async init() {
    introTrack.fade(1, 0, 1000, this.introTrackId);
    const id = this.soundtrack.play();
    this.soundtrack.fade(0, 1, 1000, id);

    // 
    const speedup = 10;
    // Await character load
    /* eslint no-constant-condition: ["error", { "checkLoops": false }] */
    while (true) {
      await new Promise(resolve => setTimeout(resolve, 500));
      if (this.msg.capacity &&
        Object.values(this.characters).length === this.msg.capacity) break;
      console.log('Awaiting config')
    }

    // Play different intros
    if (speedup == 1) {
      await this.gate.awaitIntro();
    } else {
      this.gate.awaitIntro();
    }
    await this.track.animateIntro(2000 / speedup);
    await this.skybox.animateIntro(1000 / speedup);
    await this.camera.animateIntro(2000 / speedup);
    // Play spawn intro
    const spawnDuration = 800 / speedup;
    const characters = Object.values(this.characters);
    const camPromise = this.camera.animateSpawn(characters.length * spawnDuration);
    for (let k = 0; k < characters.length; k++) {
      characters[k].animateIntro(2000 / speedup);
      await new Promise(resolve => setTimeout(resolve, spawnDuration));
    }
    await camPromise;
    await new Promise(resolve => setTimeout(resolve, 1000 / speedup));
    await this.camera.animateFpv(1000 / speedup);
    // 
    this.camera.trackCharacter = this.characters[this.playerId];
    // Start game
    await this.overlay.showGameOverlay();
    await this.api.ready();
    this.keeper.animateIntro(2000);
  }

  destroy() {
    this.api.destroy();
    this.widgets.forEach(widget => widget.destroy());
    this.characters = {};
    // Clear bots
  }

  onMessage(msg) {
    if (this.msg.phase != msg.phase) {
      this.widgets.forEach(widget => widget.onNewPhase(msg.phase));
      if (msg.phase == PhaseType.PRUNE) {
        // msg.players["3"].arrived = 2;
        clearTimeout(this.phaseTimeout);
        this.phaseTimeout = setTimeout(() => {
          this.widgets.forEach(widget => widget.onReady());
        }, this.msg.pruneDuration - 3000);
      } else if (msg.phase == PhaseType.RUN) {
        clearTimeout(this.phaseTimeout);
        this.phaseTimeout = setTimeout(() => {
          this.widgets.forEach(widget => widget.onCareful());
        }, msg.safeDuration);
      }
    }

    this.msg = msg;
    // Create players if needed
    Object.keys(msg.players).forEach((playerId, idx) => {
      if (this.characters[playerId]) return;
      const y = this.width * ((idx + .5) / this.msg.capacity - .5);
      const modelId = MODEL_IDS[(idx + this.modelOffset) % MODEL_IDS.length];
      this.characters[playerId] = new Character(this, playerId, modelId, y);
      this.widgets.push(this.characters[playerId]);
    });
    // Update widgets
    this.widgets.forEach(widget => widget.onMessage(msg));
  }

  setJumpPressed(pressed) {
    this.api.setJumpPressed(pressed);
  }

  update() {
    this.widgets.forEach(widget => widget.update());
  }
}