import * as THREE from "three";
import { VOXMesh, VOXLoader } from './VOXLoader.js';
import { hashStr } from "./hash";

export function _filePath(subpath) {
  return `https://cryptopets.tech/data/${hashStr(subpath)}`;
}

function petModelUrl(petId) {
  // return _filePath(`pets/${petId}/model.vox`);
  return `/data/${petId}/model.vox`
}


export function normalizeMesh(mesh) {
  const group = new THREE.Group();
  group.add(mesh);

  const size = new THREE.Vector3();
  const center = new THREE.Vector3();
  const boundingBox = new THREE.Box3();

  // Rotate mesh
  mesh.rotation.x = Math.PI / 2;

  // Rotate & rescale based on mesh height
  boundingBox.setFromObject(mesh);
  boundingBox.getSize(size);
  const s = 1 / size.z;
  mesh.scale.setScalar(s);

  // Center xy for base
  boundingBox.setFromObject(mesh);
  boundingBox.getCenter(center);

  // Re-scale
  mesh.position.x = -center.x;
  mesh.position.y = -center.y;

  // Offset z for model
  boundingBox.setFromObject(mesh);
  boundingBox.getCenter(center);
  boundingBox.getSize(size);
  mesh.position.z = size.z / 2 - center.z;

  return group;
}

export async function loadPetModel(url) {
  const loader = new VOXLoader();
  const chunks = await loader.loadPromise(url);
  const chunk = chunks[0];

  // Load mesh & base
  const mesh = new VOXMesh(chunk);
  mesh.castShadow = true;
  mesh.receiveShadow = true;
  // Load base
  return normalizeMesh(mesh);
}

export function setFullscreen(enabled) {
  const doc = window.document;
  const docEl = doc.documentElement;
  const requestFullScreen =
    docEl.requestFullscreen ||
    docEl.mozRequestFullScreen ||
    docEl.webkitRequestFullScreen ||
    docEl.msRequestFullscreen;
  const cancelFullScreen =
    doc.exitFullscreen ||
    doc.mozCancelFullScreen ||
    doc.webkitExitFullscreen ||
    doc.msExitFullscreen;
  if (enabled) {
    requestFullScreen.call(docEl);
  } else {
    cancelFullScreen.call(doc);
  }
}

export function isFullscreen() {
  const doc = window.document;
  return (
    !doc.fullscreenElement &&
    !doc.mozFullScreenElement &&
    !doc.webkitFullscreenElement &&
    !doc.msFullscreenElement
  );
}


export function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
}