import { TweenParent } from '../utils/tween';

export default class Widget extends TweenParent {
  constructor(game) {
    super();
    this.game = game;
    this.msg = {};
  }

  // Methods to override if needed
  async load() {
    // Override if needed
  }

  onNewPhase(phase) {
    // Override if needed
  }

  onReady() {
    // Override if needed
  }

  onCareful() {
    // Override if needed
  }

  onMessage(msg) {
    this.msg = msg;
    this.player = this.msg.players[this.game.playerId];
  }

  destroy() {
    this.tweens.removeAll();
  }
}