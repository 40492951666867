import * as THREE from 'three'
import { TweenParent, TWEEN } from '../utils/tween';

export default class Ring extends TweenParent {
  constructor(scene, radius, innerRadius, circleCol, visibe) {
    super();
    // 
    this.group = new THREE.Group();
    this.geom = new THREE.TorusGeometry(radius, innerRadius, 8, 64);
    const circleGeom = new THREE.CircleGeometry(radius, 16);
    this.torusMat = new THREE.MeshBasicMaterial({
      vertexColors: true,
      transparent: true,
      opacity: .8,
    });
    this.circleMat = new THREE.MeshBasicMaterial({
      color: circleCol,
      side: THREE.DoubleSide,
      transparent: true,
      opacity: .5,
    });
    const torusMesh = new THREE.Mesh(this.geom, this.torusMat);
    const circleMesh = new THREE.Mesh(circleGeom, this.circleMat);

    this.group.add(torusMesh);
    this.group.add(circleMesh);

    // Animation variables
    const count = this.geom.attributes.position.count;
    this.geom.setAttribute('color', new THREE.BufferAttribute(new Float32Array(count * 3), 3));
    this.tilePos = [...Array(count)];
    this.tileCol = [...Array(count)];
    this.tileShade = [...Array(count)];
    const col = new THREE.Color(0xffffff);
    const pos = this.geom.attributes.position;
    const colors = this.geom.attributes.color;
    // const dir = new THREE.Vector3(1, 0, 0);
    for (let i = 0; i < count; i += 3) {
      const x = (pos.getX(i) + pos.getX(i + 1) + pos.getX(i + 2)) / 3;
      const y = (pos.getY(i) + pos.getY(i + 1) + pos.getY(i + 2)) / 3;
      const z = (pos.getZ(i) + pos.getZ(i + 1) + pos.getZ(i + 2)) / 3;
      const vec = (new THREE.Vector3(x, y, z)).normalize();
      // const dot = vec.cross(dir);
      const dot = Math.atan2(vec.y, vec.x) / Math.PI;
      this.tilePos[i] = (1 - dot) / 2; //  (1 - x / RADIUS) / 2;
      this.tileCol[i] = new THREE.Color(0)
      // Init col
      colors.setXYZ(i, col.r, col.g, col.b);
      colors.setXYZ(i + 1, col.r, col.g, col.b);
      colors.setXYZ(i + 2, col.r, col.g, col.b);
    }
    if (!visibe) this.group.visible = false;
    scene.add(this.group);
  }

  async animateShow() {
    this.group.visible = true;
    new TWEEN.Tween({ t: 0 }, this.tweens)
      .to({ t: 1 }, 400)
      .onUpdate(({ t }) => {
        this.torusMat.opacity = t * .8;
        this.circleMat.opacity = t * .5;
      })
      .start();
    await this.tweens.promise();
  }

  // Animations
  animateTiles(colA, colB) {
    this.tweens.removeAll();
    const duration = 2000;
    const count = this.geom.attributes.position.count;
    const colors = this.geom.attributes.color;
    const col = new THREE.Color(0);
    new TWEEN.Tween({ t: 0 }, this.tweens)
      .to({ t: 1 }, duration)
      .onUpdate(({ t }) => {
        for (let i = 0; i < count; i += 3) {
          if (t - this.tilePos[i] > .5) t -= 1;
          if (t - this.tilePos[i] < -.5) t += 1;
          const dist = 1 - Math.min(1, Math.abs(t - this.tilePos[i]) / 0.2);
          col.lerpColors(colA, colB, dist);
          colors.setXYZ(i, col.r, col.g, col.b);
          colors.setXYZ(i + 1, col.r, col.g, col.b);
          colors.setXYZ(i + 2, col.r, col.g, col.b);
        }
        this.geom.attributes.color.needsUpdate = true;
      })
      .repeat(Infinity)
      .start();
  }
}