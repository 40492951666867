export class PhaseType {
  static INIT = 0;
  static WAIT = 1;
  static RUN = 2;
  static PRUNE = 3;
  static DONE = 4;
}

const DISTANCE = 100;
const CAPACITY = 6;
const JUMP_DURATION = 600;
const DEBOUNCE_TIME = 33;
const L = 1 / 2; // Exp lambda (Inverse mean bust time in sec)
const D = 10; // Average distance
const MAX_RUN_DURATION = 10e3;
const PRUNE_DURATION = 5e3;
const DEBUG = false;

// 
const LOOP_TIME = 10;

class MockLobby {
  constructor() {
    // Inner state
    this.waitStartTime;
    this.phaseStartTime;
    this.msgPrevStr;
    this.runDuration;
    // Debounce msg
    this.lastMsgTime;
    this.debounceTimeout;
    this.loopTimeout;
    // Reset
    this.reset();
  }

  reset() {
    // State
    this.distance = DISTANCE;
    this.capacity = CAPACITY;
    this.players = {};
    this.sockets = {};
    this.phase = PhaseType.INIT;
    // Compute safe config
    const safeJumpCounts = Math.ceil(D / this._timeDistance(JUMP_DURATION));
    this.safeDuration = safeJumpCounts * JUMP_DURATION;
    this.safeJumpDistance = D / safeJumpCounts;
    console.log('Safe jump counts: ', safeJumpCounts, 'safeDuration', this.safeDuration);
    // Clear timeouts
    clearTimeout(this.debounceTimeout)
    clearTimeout(this.loopTimeout)
  }

  async _send(player) {
    // Attach lobby state
    const msg = {
      distance: this.distance,
      capacity: this.capacity,
      players: this.players,
      phase: this.phase,
    }
    await player.socket.send(msg)
  }

  async _sendUpdate(force = false) {
    const dt = Date.now() - this.lastMsgTime;
    if (dt < DEBOUNCE_TIME) {
      // Debounce updates
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => this._sendUpdate(), DEBOUNCE_TIME - dt);
      return;
    }
    // Send update if needed
    const msg = {
      pruneDuration: PRUNE_DURATION,
      jumpDuration: JUMP_DURATION,
      safeDuration: this.safeDuration,
      safeJumpDistance: this.safeJumpDistance,
      distance: this.distance,
      capacity: this.capacity,
      phase: this.phase,
      players: this.players,
    }
    if (this.msgPrevStr == JSON.stringify(msg) && !force) return;
    this.msgPrevStr = JSON.stringify(msg);
    this.lastMsgTime = Date.now();
    // Send update to all players
    await Promise.all(Object.keys(this.players).map(playerId =>
      this.sockets[playerId].send(msg)
    ));
  }

  async _beginWaiting() {
    this.phase = PhaseType.WAIT;
    await this._sendUpdate();
    this.waitStartTime = Date.now();
  }

  _sampleDuration() {
    const x = Math.random();
    const tSec = -Math.log(1 - x) / L;
    return this.safeDuration + tSec * 1e3;
  }

  _timeDistance(t) {
    const tSec = t / 1e3;
    return D * (Math.exp(L * tSec) - 1);
  }

  _jumpDistance(jumpCount) {
    const dt = jumpCount * JUMP_DURATION - this.safeDuration;
    if (dt < 0) return this.safeJumpDistance;
    return this._timeDistance(dt + JUMP_DURATION) - this._timeDistance(dt);
  }

  _arrivedCount() {
    return Object.values(this.players).filter(
      player => player.arrived).length;
  }

  _start() {
    this._startPrunePhase();
    this._gameLoop();
  }

  // Main phases
  _gameLoop() {
    if (this.phase == PhaseType.RUN) {
      this._runPhase();
    } else if (this.phase == PhaseType.PRUNE) {
      this._prunePhase();
    } else {
      // Abort game loop
      return;
    }
    clearTimeout(this.loopTimeout)
    this.loopTimeout = setTimeout(() => this._gameLoop(), LOOP_TIME);
  }


  _startRunPhase() {
    if (DEBUG) console.log('RUN PHASE')
    this.phase = PhaseType.RUN;
    this.phaseStartTime = Date.now();
    // Make everyone jump
    this.runDuration = this._sampleDuration();
    // Is this useful?
    this.runDuration = Math.min(this.runDuration, MAX_RUN_DURATION);

    // this.runDuration = 3500;
    // this.runDuration = 10000 * 10;
    console.log('RUN DURATION S', (this.runDuration / 1000).toFixed(1))
    
    // Reset players
    Object.values(this.players).forEach(player => {
      player.pruned = false;
      player.jumpTime = null;
      player.jumpDistance = 0;
      player.nextJumpDistance = 0;
      player.jumpCount = 0;
    });
  }

  _runPhase() {
    const now = Date.now();
    // Check jumps
    Object.values(this.players).forEach(player => {
      // Skip arrived players
      if (player.arrived) return;
      // Check if arrived 
      if (this._jumpDone(player) && player.pos >= this.distance) {
        // Commit starting position
        player.pos = player.phasePos;
        player.arrived = this._arrivedCount() + 1;
      }
      // Process jumps
      if (player.jumpPressed) this._jump(player)
    });
    // Send updates
    this._sendUpdate();
    // Exit run phase if needed
    if (now - this.phaseStartTime > this.runDuration) {
      this._startPrunePhase();
    }
  }

  async _startPrunePhase() {
    if (DEBUG) console.log('PRUNE PHASE')
    this.phase = PhaseType.PRUNE;
    this.phaseStartTime = Date.now();
    const players = Object.values(this.players);
    players.sort((p1, p2) => p2.pos - p1.pos);
    // TODO: Check what's needed here
    players.forEach(player => {
      if (player.arrived) return;
      if (this._jumpDone(player)) {
        // Commit starting position
        player.pos = player.phasePos;
        if (player.pos >= this.distance)
          player.arrived = this._arrivedCount() + 1;
      } else {
        // Revet to starting position
        player.phasePos = player.pos;
        player.pruned = true;
      }
      // Clear jump
      player.jumpTime = null;
      player.jumpDistance = 0;
    });
    this._sendUpdate();
  }

  async _prunePhase() {
    const now = Date.now();
    // Exit run phase if needed
    if (now - this.phaseStartTime > PRUNE_DURATION) {
      if (this._arrivedCount() < 3)
        this._startRunPhase()
      else
        this._donePhase();
    }
  }

  async _donePhase() {
    if (DEBUG) console.log('DONE PHASE')
    this.phase = PhaseType.DONE;
    await this._sendUpdate();
  }


  _jumpDone(player) {
    const jumpTime = player.jumpTime;
    if (DEBUG) console.log('jumpDone', player.jumpTime)
    if (!jumpTime) return true;
    const time = Math.min(Date.now(), this.phaseStartTime + this.runDuration);
    const jumpDone = time - jumpTime > JUMP_DURATION;
    if (DEBUG) console.log('deta time ', time - jumpTime)
    if (jumpDone) {
      player.phasePos += player.jumpDistance;
      player.jumpDistance = 0;
      player.jumpTime = null;
    }
    return jumpDone;
  }

  _jump(player) {
    if (!this._jumpDone(player)) return false;
    if (this.phase != PhaseType.RUN) return false;
    if (player.arrived) return false;
    if (player.phasePos >= this.distance) return false;
    // 
    const time = Date.now();
    player.jumpTime = time;
    player.jumpDistance = this._jumpDistance(player.jumpCount);
    player.nextJumpDistance = this._jumpDistance(player.jumpCount + 1);
    player.jumpCount += 1;
    return true;
  }

  // Player actions (TODO: parse messages)
  _inLobby(playerId) {
    return !!this.players[playerId];
  }

  async destroy() {
    clearTimeout(this.phaseTimeout)
    clearTimeout(this.debounceTimeout)
  }

  async join(playerId, socket) {
    if (this.phase != PhaseType.INIT) return false;
    if (this._inLobby(playerId)) return false;
    if (Object.keys(this.players).length == this.capacity) return false;
    // Create player
    this.players[playerId] = {
      // Player state
      ready: false,
      pos: 0,
      phasePos: 0,
      jumpTime: null,
      jumpCount: 0,
      jumpDistance: 0,
      nextJumpDistance: 0,
      pruned: false,
      jumpPressed: false,
      arrived: 0,
    };
    this.sockets[playerId] = socket;
    this._sendUpdate();
    // If the lobby is full, start the game
    if (Object.keys(this.players).length == this.capacity)
      this._beginWaiting();
  }

  readyCount() {
    return Object.values(this.players).filter(
      player => player.ready
    ).length;
  }

  async ready(playerId) {
    if (!this._inLobby(playerId)) return false;
    if (this.players[playerId].ready) return false;
    this.players[playerId].ready = true;
    this._sendUpdate();
    const timeout = false;
    // TODO: add timeout
    if (timeout || this.readyCount() == this.capacity)
      this._start();
  }

  async setJumpPressed(playerId, pressed) {
    if (!this._inLobby(playerId)) return false;
    this.players[playerId].jumpPressed = pressed;
  }

  async getUpdate() {
    this._sendUpdate();
  }
}

export default new MockLobby();