import * as THREE from 'three'
import TWEEN from '@tweenjs/tween.js';
import { PhaseType } from '../core/api';
import { KEEPER_POS } from './keeper';
import Widget from './widget';
import { Howl } from 'howler';

const phaseSound = new Howl({
  src: [require('@/assets/sounds/phase.mp3')],
});
const startSound = new Howl({
  src: [require('@/assets/sounds/start.wav')],
});
const stopSound = new Howl({
  src: [require('@/assets/sounds/stop.wav')],
  volume: 0.7,
});

export const RADIUS = 1000;
const DARK_COL = 0x000000;

export default class Skybox extends Widget {
  constructor(game) {
    super(game);
    // 
    this.radius = RADIUS;
    this.group = new THREE.Group();
    this.geom = new THREE.IcosahedronGeometry(RADIUS, 8);
    const mat = new THREE.MeshBasicMaterial({
      vertexColors: true,
      side: THREE.DoubleSide,
    });
    const mesh = new THREE.Mesh(this.geom, mat);
    this.group.add(mesh);

    // Add wireframe
    const wireframe = new THREE.WireframeGeometry(this.geom);
    this.lineMat = new THREE.LineBasicMaterial({ color: DARK_COL });
    const line = new THREE.LineSegments(wireframe, this.lineMat);
    this.group.add(line);

    // Debug line
    // const lMat = new THREE.LineBasicMaterial({
    //   color: 0x00ff00
    // });
    // const points = [];
    // points.push(new THREE.Vector3(0, 0, 0));
    // points.push((new THREE.Vector3(4.85, 0, 1)).multiplyScalar(RADIUS));
    // const lGeom = new THREE.BufferGeometry().setFromPoints(points);
    // const l = new THREE.Line(lGeom, lMat);
    // scene.add(l);

    // Animation variables
    const count = this.geom.attributes.position.count;
    this.geom.setAttribute('color', new THREE.BufferAttribute(new Float32Array(count * 3), 3));
    this.tilePos = [...Array(count)];
    this.tileCol = [...Array(count)];
    this.tileShade = [...Array(count)];
    const pos = this.geom.attributes.position;
    for (let i = 0; i < count; i += 3) {
      const x = (pos.getX(i) + pos.getX(i + 1) + pos.getX(i + 2)) / 3;
      const y = (pos.getY(i) + pos.getY(i + 1) + pos.getY(i + 2)) / 3;
      const z = (pos.getZ(i) + pos.getZ(i + 1) + pos.getZ(i + 2)) / 3;
      const vec = (new THREE.Vector3(x, y, z)).normalize();
      const dot = vec.dot(KEEPER_POS);
      this.tilePos[i] = (1 - dot) / 2; //  (1 - x / RADIUS) / 2;
      this.tileCol[i] = new THREE.Color(0)
      this.tileShade[i] = Math.random();
    }
    // console.log(Math.min(...this.tilePos), Math.max(...this.tilePos));
    this.tileAnimCol = new THREE.Color(0);
    this.lineAnimCol = new THREE.Color(0);

    // Timeouts
    this.introTimout = null;

    // Add group
    // group.rotation.x = Math.PI / 2;
    this.group.visible = false;
    game.scene.add(this.group);
  }

  destroy() {
    clearTimeout(this.introTimout);
  }


  async onNewPhase(phase) {
    if (phase == PhaseType.RUN) {
      startSound.play();
      await this.animateTiles(new THREE.Color(0x182539), 100);
      this.animateTiles(new THREE.Color(0x08960d), 5000, 2);
    }
    else if (phase == PhaseType.PRUNE) {
      stopSound.play();
      this.animateTiles(new THREE.Color(0x961008), 5000, 1);
    } else if (phase == PhaseType.DONE) {
      await this.animateTiles(new THREE.Color(0x182539), 100);
      this.animateTiles(new THREE.Color(0x8a168c), 5000, 2);
    }
  }

  async onReady() {
    await this.animateTiles(new THREE.Color(0x182539), 100);
  }

  async onCareful() {
    phaseSound.play();
    await this.animateTiles(new THREE.Color(0x182539), 100);
    this.animateTiles(new THREE.Color(0xb3700b), 5000, 2);
  }

  async animateIntro(duration) {
    this.group.visible = true;
    await this.animateTiles(new THREE.Color(0x182539), duration);
    this.animateLines(new THREE.Color(0x707070), Math.min(600, duration));
  }

  // Animations
  async animateTiles(color, duration, mode = 0) {
    this.tweens.removeAll();
    if (this.tileAnimCol.getHex() == color.getHex()) return;
    const tileTime = Math.min(300, duration);
    const count = this.geom.attributes.position.count;
    const colors = this.geom.attributes.color;
    const col = new THREE.Color(0);
    const dark = new THREE.Color(0);
    this.tileAnimCol.copy(color)
    new TWEEN.Tween({ t: 0 }, this.tweens)
      .to({ t: duration }, duration)
      .onUpdate(({ t }) => {
        for (let i = 0; i < count; i += 3) {
          // Skip colors
          if (this.tileCol[i].getHex() == color.getHex()) continue;
          // 
          const d = this.tilePos[i];
          const fact = t / tileTime + (1 - duration / tileTime) * d;
          const phase = Math.max(0, Math.min(1, fact));
          const tempColor = mode == 2 || (mode == 1 && d > 0.005);
          if (tempColor) {
            // Transition through color
            if (phase < .5)
              col.lerpColors(this.tileCol[i], color, phase * 2);
            else
              col.lerpColors(color, this.tileCol[i], (phase - .5) * 2);
            // 
          } else {
            col.lerpColors(this.tileCol[i], color, phase);
          }
          // Darken if needed
          col.lerp(dark, this.tileShade[i] / 5);
          // Set color
          colors.setXYZ(i, col.r, col.g, col.b);
          colors.setXYZ(i + 1, col.r, col.g, col.b);
          colors.setXYZ(i + 2, col.r, col.g, col.b);
          // Cache color
          if (phase == 1 && !tempColor) this.tileCol[i] = color;
        }
        this.geom.attributes.color.needsUpdate = true;
      })
      .start();
    await this.tweens.promise();
  }

  // Animations
  async animateLines(color, duration) {
    const col = new THREE.Color();
    const oldColor = this.lineAnimCol;
    this.lineAnimCol = color;
    new TWEEN.Tween({ t: 0 }, this.tweens)
      .to({ t: 1 }, duration)
      .onUpdate(({ t }) => {
        col.lerpColors(oldColor, this.lineAnimCol, t);
        this.lineMat.color = col;
        this.lineMat.needsUpdate = true;
      })
      .start();
    await this.tweens.promise();
  }
}