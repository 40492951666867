<template lang="pug">
div(ref="container")
  slot
</template>

<script>
import { TWEEN } from "../js/utils/tween";

export default {
  props: {
    blink: Boolean,
    hide: Boolean,
  },

  data: () => ({
    animFrame: null,
  }),

  mounted() {
    this.tweens = new TWEEN.Group();
    if (this.blink) this.animateBlink();
    if (this.hide) this.$refs.container.style.opacity = 0;
    this.update();
  },

  destroyed() {
    this.tweens.removeAll();
    cancelAnimationFrame(this.animFrame);
  },

  methods: {
    animateBlink() {
      new TWEEN.Tween({ t: 1 }, this.tweens)
        .to({ t: 0.5 })
        .onUpdate(({ t }) => (this.$refs.container.style.opacity = t))
        .onStop(() => (this.$refs.container.style.opacity = 1))
        .duration(1000)
        .easing(TWEEN.Easing.Quadratic.In)
        .yoyo(true)
        .repeat(Infinity)
        .start();
    },

    async animateShow(show) {
      this.tweens.removeAll();
      new TWEEN.Tween({ t: show ? 0 : 1 }, this.tweens)
        .to({ t: show ? 1 : 0 })
        .onUpdate(({ t }) => {
          this.$refs.container.style.opacity = t;
        })
        .duration(500)
        .easing(TWEEN.Easing.Quadratic.In)
        .start();
      await this.tweens.promise();
    },

    update() {
      this.tweens.update();
      this.animFrame = requestAnimationFrame(this.update);
    },
  },
};
</script>