import TWEEN from '@tweenjs/tween.js';

// Add tween helpers
TWEEN.Tween.prototype.promise = function () {
  return new Promise(resolve => this.onComplete(() => {
    resolve();
  }))
}
TWEEN.Group.prototype.promise = function () {
  return Promise.all(Object.values(this._tweens).map(tween => tween.promise()))
}
TWEEN.Group.prototype.running = function () {
  return Object.values(this._tweens).length;
}

class TweenParent {
  constructor() {
    this.tweens = new TWEEN.Group();
  }

  update() {
    this.tweens.update();
  }

  destroy() {
    this.tweens.removeAll();
  }
}

export { TWEEN, TweenParent }